<template>
  <div class="wait-receive bsbb p30">
    <div class="order-list df fdc">
      <div class="item mb20 p15 bsbb" v-for="(v, i) in unfinishedOrder" :key="i">
        <div class="df aic jcsb font-size-24 fw7" style="border-bottom: 1px solid #f2f2f2; padding-bottom: 8px">
          <p>菜大全鲜果蔬菜成都店</p>
          <p>2022-12-23 00:00 下单</p>
        </div>
        <div class="df aic jcsb text p15 bsbb">
          <div class="df aic font-size-20">
            <img :src="v.order_img" class="mr20" />
            <p style="color: #77d477">商家正在发货中...请耐心等待</p>
          </div>
          <!-- <van-button type="default" @click="goCommend(v.id)">评价</van-button> -->
        </div>
      </div>
    </div>
  </div>
<!-- <div class="wait-receive bsbb">
    <p class="mb20 font-size-38 fw7">店家备货中</p>
    <div class="content p20 bsbb">
      <div class="title">
        <p class="font-size-30 fw7">
          菜大全鲜果蔬菜成都店<van-icon name="arrow" />
        </p>
      </div>
      <div class="goods-list">
        <div class="df aic jcsb msg mt20">
          <div class="df aic font-size-24 fw7">
            <img src="../../assets/images/xg/list5.png" class="mr20" />
            <p>[新鲜到货] 智利进进口4J车厘子100h</p>
          </div>
          <div class="df fdc re">
            <p class="font-size-24">实付49.3</p>
            <div class="font-size-16">69.3</div>
          </div>
        </div>
        <div class="df aic jcsb msg mt20">
          <div class="df aic font-size-24 fw7">
            <img src="../../assets/images/xg/list5.png" class="mr20" />
            <p>[新鲜到货] 智利进进口4J车厘子100h</p>
          </div>
          <div class="df fdc re">
            <p class="font-size-24">实付49.3</p>
            <div class="font-size-16">69.3</div>
          </div>
        </div>
      </div>
      <div class="rest font-size-18 df aic jcc mt20 mb20">
        <span>剩余商品 <van-icon name="arrow-down" /></span>
      </div>
      <div class="mt20 mb20 font-size-22 tip">
        <div class="df aic jcsb mt20">
          <span>包装费</span>
          <span>¥2</span>
        </div>
        <div class="df aic jcsb mt20">
          <div class="df fdc">
            <span>配送费</span>
            <span class="red">免费配送</span>
          </div>
          <span>¥0</span>
        </div>
        <div class="re font-size-24 mt20">
          <p>
            <span class="mr20">总优惠 <i style="color: red">¥24.5</i></span>
            <span class="fw7 font-size-26">实付款51.3<van-icon name="arrow-up" /></span>
          </p>
        </div>
      </div>
      <div class="address font-size-24">
        <div class="df aic jcsb">
          <span>收货地址</span>
          <span>华阳镇街道天目中心2号楼一楼大厅右侧</span>
        </div>
        <div class="df aic jcsb">
          <span>收货人</span>
          <span>南柱赫17608139720</span>
        </div>
        <div class="df aic jcsb">
          <span>如遇缺货</span>
          <span>缺货时电话与我联系</span>
        </div>
        <div class="df aic jcsb">
          <span>订单号</span>
          <div class="df aic">
            <span class="mr20">4050 3500 9693 3905 055 </span>
            <img src="../../assets/images/xg/copyicon.png" class="copy" />
          </div>
        </div>
        <div class="df aic jcsb">
          <span>送达时间</span>
          <span>尽快送达[19:55]</span>
        </div>
        <div class="df aic jcsb">
          <span>配送方式</span>
          <span>骑士专送</span>
        </div>
        <div class="df aic jcsb">
          <span>支付方式</span>
          <span>在线支付</span>
        </div>
        <div class="df aic jcsb">
          <span>下单时间</span>
          <span>2022 12 23 00:00</span>
        </div>
      </div>
    </div>
  </div> --></template>

<script>
import { getMyOrderReq } from '@/utils/api.js'
export default {
  data() {
    return {
      unfinishedOrder: []
    }
  },
  mounted() {
    getMyOrderReq({ page: 1, page_size: 50 }).then((res) => {
      this.orderList = res.data.list;
      this.unfinishedOrder = this.orderList.filter(v => v.payment_status == 30)
      console.log(this.unfinishedOrder);
    });
  },
};
</script>

<style lang="scss" scoped>
.wait-receive {
  width: 100%;
  height: 100%;
  padding: 20px 30px;

  .order-list {
    .item {
      width: 684px;
      height: 213px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 1);

      .text {
        img {
          width: 100px;
          height: 100px;
          border-radius: 8px;
        }

        .van-button {
          width: 129px;
          height: 57px;
          border-radius: 15px;
        }
      }
    }
  }

  // .content {
  //   width: 684px;
  //   height: 100%;
  //   border-radius: 20px;
  //   background: rgba(255, 255, 255, 1);

  //   .goods-list {
  //     .msg {
  //       img {
  //         width: 84px;
  //         height: 84px;
  //       }

  //       .re {
  //         text-align: right;

  //         >div {
  //           text-decoration: line-through;
  //           color: #ddd;
  //         }
  //       }
  //     }
  //   }

  //   .tip {
  //     border-bottom: 1px solid #f2f2f2;
  //     padding-bottom: 20px;

  //     .red {
  //       color: red;
  //       font-size: 14px !important;
  //     }

  //     .re {
  //       text-align: right;
  //     }
  //   }

  //   .address {
  //     >div {
  //       margin-top: 20px;
  //     }

  //     .copy {
  //       width: 18.68px;
  //       height: 18.68px;
  //       opacity: 0.75;
  //     }
  //   }
  // }
}
</style>